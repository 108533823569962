import React from "react"
import { graphql } from "gatsby"
import MDXRenderer from "gatsby-mdx/mdx-renderer"

import Layout from "../components/layout"
import Logo from "../components/logo"
import SEO from "../components/seo"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title={data.mdx.frontmatter.title} keywords={[`soso`, `sosolin`]} />
      <div className="postTemplate">
        <Logo />
        <p className="title" key={data.mdx.id}>
          {data.mdx.frontmatter.title}
        </p>
        <MDXRenderer>{data.mdx.code.body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query postByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      id
      frontmatter {
        title
        path
        description
        date
      }
      code {
        body
        scope
      }
      internal {
        content
        type
        contentDigest
        owner
      }
    }
  }
`
